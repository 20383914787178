import {defineStore} from "pinia";
import {useConfigStore} from "./config"
import {useAuthStore} from "./auth";
import {AppContext} from "@hc/graphql";
import {ref} from "vue"
import {useCronStore} from "./cron";
import axios from "axios";
import {Logger} from "@hc/base";
import {useUiStore} from "./ui";
import {AppBootParams} from "../bootstrap";
import {vueContext} from "../../vue";

const logger = new Logger('AppStore')

export type AppDeploymentInfo = {
  ref: string
  build: number
}

export const useAppStore = defineStore('app', () => {
  const startup = ref(true)
  const loading = ref(false)
  const startupMessage = ref(null)
  const deploymentInfo = ref<AppDeploymentInfo>(null)
  const newVersionAvailable = ref<boolean>(false)
  const config = useConfigStore()
  const auth = useAuthStore()
  const cron = useCronStore()
  const ui = useUiStore()

  async function checkDeployment(){
    const url = config.get('services.core') + '/build.json'
    logger.log("Check deployment url=%s", url)
    const result = await axios.get(url)
    const currentDeployment: AppDeploymentInfo = result.data
    if(!deploymentInfo.value){
      logger.log("First deployment check", currentDeployment)
      deploymentInfo.value = currentDeployment
    } else if(deploymentInfo.value.build != currentDeployment.build){
      logger.log("New deployment detected", currentDeployment)
      deploymentInfo.value = result.data
      newVersionAvailable.value = true
    }
  }

  async function boot(params: AppBootParams){
    this.startupMessage = 'Starting up'
    config.app = params.appName
    config.scope = params.scope
    config.uiPath = params.uiPath
    config.settings = params.api.config
    config.api = params.api
    this.startupMessage = 'Initializing authentication'
    await auth.boot(params)
    this.startupMessage = 'Starting cronjobs'

    if(config.isProd){
      cron.register({
        name: 'AppStore.checkDeployment',
        timeout: 300000,
        handler: checkDeployment
      })
      checkDeployment()
    }

    if(params.afterBoot) {
      this.startupMessage = 'Initializing app'
      await params.afterBoot(vueContext.root)
    }

    this.startup = false
    this.startupMessage = null
  }

  function reload(){
    window.location.reload()
  }

  return {
    startup,
    loading,
    startupMessage,
    boot,
    config,
    auth,
    cron,
    ui,
    deploymentInfo,
    newVersionAvailable,
    reload,
    isDev: config.isDev,
    isProd: config.isProd,
  }
})